<template>
  <div class="card-registration__item">
    <div class="card-registration__inner">
      <!-- <div class="card-registration__top">
        <h5 class="primary-title">{{ $t("dateRegistration.title") }}</h5>
      </div> -->
      <div class="card-registration__center">
        <div class="exam-locations primary-text">
          <h3 class="title">{{ $t("dateRegistration.testLocation") }}</h3>
          <p class="address">{{ detail.exam_address }}</p>
          <h4 class="date">
            {{ $t("dateRegistration.date") }} -
            {{ i18n.global.locale.value == 'ko' ?  getFullDate(detail.exam_time, true) : handleDate(detail.exam_time, 'dd mmmm') }},
            {{ handleTime(detail.exam_time) }}

          </h4>
        </div>
        <div class="topic-level primary-text">
          <h4 class="title">{{ $t("dateRegistration.level") }}</h4>
          <p class="level">
            {{ $t("dateRegistration.topik") }}
            <span style="border: none" v-if="detail.topik_type == 1">I</span
            ><span style="border: none" v-else>II</span>
          </p>
        </div>
        <div class="buttons">
          <PrimaryButton
            :title="$t('dateRegistration.look')"
            @click="changeAnketaQuery(detail.id)"
          />
          <PrimaryButton
            :disabled="loadActive"
            :title="$t('dateRegistration.download')"
            @click="changeAnketaSaveQuery(detail.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { useRouter } from "vue-router";
import { useFormatter } from "@/utils/formatter";
import { useLanguage } from "@/utils/language";
import PrimaryButton from "../PrimaryButton.vue";
// import { months } from "@/utils/constants";

export default {
  props: {
    detail: Object,
    pdfIndex: Number,
    loadActive: Boolean,
  },
  setup() {
    // const { i18n } = useLanguage();
    const router = useRouter();
    const changeAnketaQuery = (index) => {
      let routeData = router.resolve({
        name: "ru_AccountAnketa",
        query: { anketa_id: index },
      });
      // router.replace({ name: "ru_Anketa", query: { anketa_id: index } });
      window.open(routeData.href, "_blank");
    };
    const changeAnketaSaveQuery = (index) => {
      // router.replace({ query: { anketa_save_id: index } });
      let routeData = router.resolve({
        name: "ru_AccountAnketa",
        query: { anketa_save_id: index },
      });
      // router.replace({ name: "ru_Anketa", query: { anketa_id: index } });
      window.open(routeData.href, "_blank");
    };
    // const getFullDate = (fullDate) => {
    //   // let date = new Date(fullDate);
    //   // const options = {
    //   //   hour: "numeric",
    //   //   minute: "numeric",
    //   //   month: "long",
    //   //   day: "numeric",
    //   // };
    //   // return date.toLocaleDateString(i18n.global.locale.value, options);
    // };
    return {
      changeAnketaSaveQuery,
      changeAnketaQuery,
      // getFullDate,
      ...useLanguage(),
      ...useFormatter(),
    };
  },
  components: { PrimaryButton },
};
</script>

<style lang="scss" scoped>
@keyframes loadAnimate {
  0% {
    transform: scale(1);
  }

  0% {
    transform: scale(1.1);
  }
}

.card-registration {
  &__item {
    width: 100%;
    background: var(--white);
    border-radius: 0.63rem;
    padding: 1.88rem 0;
    overflow: hidden;
    margin-bottom: 1.25rem;
    border: 0.13rem solid var(--primary-blue);
  }

  &__top {
    margin-bottom: 1.875rem;
    .title {
      font-weight: 500;
      line-height: 143.7%;
      font-size: 1.43em;
    }
  }

  &__inner {
    margin: 0 auto;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .buttons {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1.88rem;
    width: 100%;
    flex: 0 0 20%;
    flex-wrap: wrap;
  }

  &__center {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1.88rem;
    flex-wrap: wrap;

    .exam-locations {
      width: 31.25rem;
      margin-right: 2.5rem;
      .title {
        font-weight: 500;
        line-height: 143.7%;
        color: var(--primary-blue);
        margin-bottom: 1.25rem;
      }

      .address {
        max-width: 35rem;
        font-weight: 500;
        line-height: 143.7%;
        color: #1c1c1c;
      }
      .date {
        font-weight: 400;
        line-height: 143.7%;
        color: #1c1c1c;
      }
    }

    .topic-level {
      .title {
        font-weight: 500;
        line-height: 1.6875rem;
        color: var(--primary-blue);
        margin-bottom: 1.25rem;
      }

      .level {
        font-weight: 500;
        line-height: 143.7%;
        color: #000000;
        width: max-content;
      }
    }
  }

  &__bottom {
    .gradient-btn {
      padding: 0.1875rem 2.5rem;
      font-weight: 500;
      font-size: 1.375em;
      line-height: 143.7%;
      color: #ffffff;
      cursor: pointer;
      transition: all 0.3s ease;

      &:disabled {
        opacity: 0.7;
      }

      &:first-child {
        margin-right: 0.9375rem;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .card-registration {
    width: 100%;
    &__item {
      width: 100%;
    }
  }
}

@media screen and (max-width: 992px) {
  .card-registration {
    &__item {
      width: 100%;
      padding-bottom: 2.5rem;
    }

    &__center {
      .exam-locations {
        margin-right: 5.125rem;
        .title {
          font-size: 1.06em;
        }

        .address {
          font-size: 1.25em;
        }

        .date {
          font-size: 1.06em;
        }
      }

      .topic-level {
        .title {
          font-size: 1.06em;
        }

        .level {
          font-size: 1.25em;
        }
      }
    }

    &__bottom {
      .gradient-btn {
        font-size: 1.17em;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .card-registration {
    &__item {
      padding: 1.6875rem 0;
    }

    &__inner {
      padding: 0 0.75rem;
    }

    &__top {
      .title {
        font-size: 0.93em;
      }
    }

    &__center {
      flex-direction: column;
      .exam-locations {
        max-width: 20.625rem;
        margin-bottom: 30px;
        .title {
          font-size: 0.92em;
        }

        .address {
          font-size: 0.98em;
        }

        .date {
          font-size: 0.84em;
        }
      }

      .topic-level {
        .title {
          font-size: 0.92rem;
          margin-bottom: 10px;
        }

        .level {
          font-size: 0.98rem;
        }
      }
    }
    &__bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .gradient-btn {
        width: 100%;
        margin: 0;
        font-size: 0.92rem;
        padding: 0.4rem 2.5rem;

        &:first-child {
          margin: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
