<template>
  <div class="card-registration">
    <div class="alert" v-if="appealsList.length == 0">Вы не зарегистрированы на экзамен</div>
    <template v-for="(item, index) in appealsList" :key="index">
      <AnketaItem
        :detail="item"
        :pdfIndex="item.id"
        :loadActive="isLoadActive"
        @click="pdfIndex = index"
        @pdf-active="getPdfActive"
        @pdf-load="getLoadActive"
      />
    </template>
  </div>
  <AnketaView
    v-if="registerDates[pdfIndex]?.anketa"
    :userAnketa="registerDates[pdfIndex]?.anketa"
    :loadActive="isLoadActive"
    @pdf-active="getPdfActive"
    @pdf-load="getLoadActive"
  />
</template>

<script>
import AnketaItem from "@/components/anketa/AnketaItem.vue";
import { registerDates } from "@/utils/constants";
import AnketaView from "@/components/anketa/AnketaView.vue";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from 'vuex';

export default {
  components: {
    AnketaView,
    AnketaItem,
  },
  setup(_, { emit }) {
    const pdfActive = ref(false);
    
    const pdfIndex = ref(0);

    const route = useRoute();

    const store = useStore()

    store.dispatch("appeal/getAppealsList")

    store.dispatch("appeal/getAllChoices")

    watch(
      () => route.query.anketa_id,
      () => {
        if (route.query.anketa_id != undefined) {
          pdfIndex.value = route.query.anketa_id;
        }
      }
    );

    watch(
      () => pdfActive.value,
      () => {
        if (pdfActive.value) {
          emit("loadPdf");
        }
      }
    );

    const isLoadActive = ref(false);

    const getLoadActive = (state, pdf_index) => {
      pdfIndex.value = pdf_index;
      isLoadActive.value = state;
    };

    const getPdfActive = (state, pdf_index) => {
      pdfIndex.value = pdf_index;
      pdfActive.value = state;
    };

    return {
      registerDates,
      isLoadActive,
      getPdfActive,
      pdfActive,
      pdfIndex,
      getLoadActive,
      appealsList: computed(() => store.getters["appeal/appealsList"]),
      store
    };
  },
};
</script>

<style lang="scss" scoped>
.card-registration {
  border-radius: 0.3125rem;
  margin: 0.8em 0;
  color: #000000;
  width: var(--width-cards);
  // padding-left: 3.31rem;

  .alert {
    font-size: 1.38rem;
    color: var(--error-color);
    text-align: center;
  }
}

@media screen and (max-width: 1200px) {
.card-registration {
  width: 100%;
}
}

@media screen and (max-width: 992px) {
  .card-registration {
    box-sizing: border-box;
    width: 100%;
    // padding-left: 3.31rem;

    .alert {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 576px) {
  .card-registration {
    box-sizing: border-box;
    width: 100%;
    padding: 0;
    .alert {
      text-align: center;
      font-size: 14px;
    }
  }
}
</style>
