<template>
  <transition name="fade">
    <div
      class="view-wrapper"
      :class="{ pdfActive: active }"
      @click="removeAnketaQuery()"
    >
      <div class="spinner" v-if="spinnerActive">
        <div class="loading"></div>
      </div>

      <button class="close-btn" @click="removeAnketaQuery()">
        <i class="fad fa-times"></i>
      </button>
      <AnketaPage :anketa="appealsDetail" ref="anketa" />
      <div class="view" ref="pdfView"></div>
    </div>
  </transition>
</template>
<script>
import { jsPDF } from "jspdf";
import PDFObject from "pdfobject";
import { ref, computed, onMounted } from "vue";
import { watch } from "vue";
import AnketaPage from "./AnketaPage.vue";
import { useRoute, useRouter } from "vue-router";
import { useLanguage } from "@/utils/language";
import { useStore } from "vuex";

export default {
  props: {
    loadActive: Boolean,
    userAnketa: Object,
  },

  components: {
    AnketaPage,
  },

  setup(props, { emit }) {
    let doc = null;

    const anketa = ref(null);

    const spinnerActive = ref(false);

    const route = useRoute();

    const router = useRouter();

    const { i18nRoute } = useLanguage();

    const active = ref(false);

    const svgHolder = ref(false);

    const store = useStore();

    const reloadPdf = () => {
      doc = null;
      doc = new jsPDF({
        orientation: "p",
        unit: "px",
        format: [1050, 1500],
      });

      doc.addFont("NanumGothicCoding-Regular.ttf", "NanumGothicCoding", "normal");
      doc.addFont("LINESeedKR-Rg.ttf", "LineSeed", "normal");
      doc.addFont("NanumGothicCoding-Bold.ttf", "NanumGothicCodingBold", "bold");
      doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
      doc.addFont("Roboto-Medium.ttf", "RobotoMedium", "bold");
      doc.setFont("LineSeed", "normal");
      doc.setFont("NanumGothicCoding", "normal");
      doc.setFont("NanumGothicCodingBold", "bold");
      doc.setFont("Roboto", "normal");
      doc.setFont("RobotoMedium", "bold");

      doc.html(anketa.value.$el, {
        callback: function () {
          pdfView.value.style.opacity = 1;
          spinnerActive.value = false;
          showPdf();
        },
        x: 1300,
        y: 0,
      });
    };

    const showPdf = () => {
      if (PDFObject.supportsPDFs) {
        PDFObject.embed(doc.output("bloburl"), pdfView.value, { page: 1 });
      } else {
        // window.location(doc.output("bloburl"));
        doc.output("save", "anketa.pdf");
        removeAnketaQuery();
      }
      // else if (
      //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
      //     navigator.userAgent
      //   )
      // ) {
      //   window.open(doc.output("bloburl"));
      //   removeAnketaQuery();
      // }
    };

    const removeAnketaQuery = () => {
      router.replace(i18nRoute({ name: "AccountAnketa" }));
    };

    const loadDeatil = (val) => {
      if (route.query.anketa_id != undefined) {
        store.dispatch("appeal/getAppealsDetail", {
          сb: () => {
            setTimeout(() => {
              reloadPdf();
            }, 1000);
          },
          id: val,
        });
        active.value = true;
      } else {
        active.value = false;
      }
    };

    const loadSave = () => {
      doc = null;
      doc = new jsPDF({
        orientation: "p",
        unit: "px",
        format: [1050, 1500],
      });
      doc.addFont("LINESeedKR-Rg.ttf", "LineSeed", "normal");
      doc.addFont("NanumGothicCoding-Regular.ttf", "NanumGothicCoding", "normal");
      doc.addFont("NanumGothicCoding-Bold.ttf", "NanumGothicCodingBold", "bold");
      doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
      doc.addFont("Roboto-Medium.ttf", "RobotoMedium", "bold");
      doc.setFont("LineSeed", "normal");
      doc.setFont("NanumGothicCoding", "normal");
      doc.setFont("NanumGothicCodingBold", "bold");
      doc.setFont("Roboto", "normal");
      doc.setFont("RobotoMedium", "bold");

      doc.html(anketa.value.$el, {
        callback: function (doc) {
          doc.output("save", "anketa.pdf");
          emit("pdfLoad", false, 0);
        },
        x: 1300,
        y: 0,
      });

      removeAnketaQuery();
    };

    const pdfView = ref(null);

    const savePdf = (val) => {
      if (route.query.anketa_save_id != undefined) {
        store.dispatch("appeal/getAppealsDetail", {
          сb: () => {
            loadSave();
          },
          id: val,
        });
      }
    };

    onMounted(() => {
      watch(
        () => route.query.anketa_id,
        (val) => {
          pdfView.value.style.opacity = 0;
          spinnerActive.value = true;
          loadDeatil(val);
        },
        { immediate: true }
      );
    });

    watch(
      () => route.query.anketa_save_id,
      (val) => {
        savePdf(val);
      },
      { immediate: true }
    );

    watch(
      () => props.loadActive,
      () => {
        pdfView.value.style.opacity = 0;
        if (props.loadActive) {
          savePdf();
        }
      }
    );

    return {
      pdfView,
      doc,
      reloadPdf,
      removeAnketaQuery,
      spinnerActive,
      active,
      appealsDetail: computed(() => store.getters["appeal/appealsDetail"]),
      svgHolder,
      anketa,
    };
  },
};
</script>
<style lang="scss" scoped>
.svgHolder {
  img {
    position: fixed;
    top: 0;
    left: -1050px;
    background: none;
  }
}
.close-btn {
  position: fixed;
  top: 20px;
  right: 4vw;
  font-size: 30px;
  background: none;
  color: #fff;
  cursor: pointer;
}

.view {
  width: 80%;
  height: 100vh;

  &-wrapper {
    width: 100%;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8);
    transition: all 0.2s ease;
    &.pdfActive {
      opacity: 1;
      visibility: visible;
    }
  }
}

.spinner {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  box-shadow: 0 -3em rgba(255, 255, 255, 1),
    2.25em -2.25em rgba(255, 255, 255, 0.875), 3em 0 rgba(255, 255, 255, 0.75),
    2.25em 2.25em rgba(255, 255, 255, 0.625), 0 3em rgba(255, 255, 255, 0.5),
    -2.25em 2.25em rgba(255, 255, 255, 0.375), -3em 0 rgba(255, 255, 255, 0.25),
    -2.25em -2.25em rgba(255, 255, 255, 0.125);
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(-360deg);
  }
}
</style>
